@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .outer-container {
    width: 100%;
    margin: auto;
    padding: 0px 104px;
    max-width: 1176px;
  }

  .outer-container-md {
    width: 100%;
    margin: auto;
    padding: 0px 40px;
    max-width: 1176px;
  }

  .outer-container-sm {
    padding: 0px 16px;
    width: 100%;
    margin: auto;
    max-width: 1176px;
  }

  .blog-container {
    width: 90%;
    height: max-content;
    margin: auto;
    max-width: 800px;
  }
  .required-filed::after {
    content: '*';
    color: red;
  }
}
.rdp-vhidden {
  @apply hidden;
}
